<template>
  <div>
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-list-group>

      <b-list-group-item to="/manufacturing/products">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="text-primary">Products</h5>
            <span class="text-muted">List all supported products and produced units</span>
          </div>
          <div class="col text-right">
            <h1 class="m-0"><b-icon-cpu></b-icon-cpu></h1>
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item to="/manufacturing/tests">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="text-primary">Tests</h5>
            <span class="text-muted">List all test pipelines and all results</span>
          </div>
          <div class="col text-right">
            <h1 class="m-0"><b-icon-stoplights></b-icon-stoplights></h1>
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item to="/manufacturing/boxes">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="text-primary">Boxes</h5>
            <span class="text-muted">Manage boxes and their content</span>
          </div>
          <div class="col text-right">
            <h1 class="m-0"><b-icon-box-seam></b-icon-box-seam></h1>
          </div>
        </div>
      </b-list-group-item>

    </b-list-group>
  </div>
</template>

<script>
export default {
  name: 'Manufacturing',
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Manufacturing',
          active: true
        },
      ],
    }
  },
}
</script>